/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c2171b2a-d00a-415a-bc14-648d59b3511c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0CQSD0TYZ",
    "aws_user_pools_web_client_id": "47884gr1tl0grhv5tle04qub1v",
    "oauth": {}
};


export default awsmobile;
